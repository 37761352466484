import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import { LoadingArea, Content, Container } from "./styles"
import { Context } from "../../context"
import { Header } from "./components"
import { trpc } from "src/api/trpc"
import { SignatureSignStatus, SignStatus } from "server/src/types"
import {
  StartSigning,
  ConfirmData,
  SignData,
  Signed,
  VisualizeContract,
} from "./screens"
import Download from "./screens/Download"
import { RouterOutput } from "src/types"
import dispatchToast from "@Utils/dispatchToast"
import i18n from "src/i18n"

enum PageEnum {
  start = "start",
  visualizeContract = "visualizeContract",
  confirmData = "confirmData",
  signData = "signData",
  download = "download",
  signed = "signed",
}

export type SigningData = RouterOutput["wizard"]["getSigningData"]

export type ConfirmationDataType = {
  name: string
  documentNumber: string
  email: string
}

export interface ISigningContext {
  signData: SigningData
  confirmationData?: ConfirmationDataType
  handleSetConfirmationData(data: ConfirmationDataType): void
}

export const signingContext = React.createContext<ISigningContext>(
  {} as ISigningContext
)

const ContentPage: Record<PageEnum, React.ReactElement> = {
  [PageEnum.start]: <StartSigning />,
  [PageEnum.visualizeContract]: <VisualizeContract />,
  [PageEnum.confirmData]: <ConfirmData />,
  [PageEnum.signData]: <SignData />,
  [PageEnum.signed]: <Signed />,
  [PageEnum.download]: <Download />,
}

const Signing = () => {
  const [page, setPage] = React.useState<PageEnum>(PageEnum.start)
  const [confirmationData, setConfirmationData] =
    React.useState<ConfirmationDataType>()

  const navigate = useNavigate()
  const { route } = useParams()
  const globalContext = React.useContext(Context)

  const { data } = trpc.wizard.getSigningData.useQuery(
    {
      cardId: globalContext.cardId,
      companyId: globalContext.companyId,
      category: globalContext.category,
    },
    {
      enabled: !!globalContext.cardId && !!globalContext.category,
      onSuccess: (data) => {
        const foundSignature = data.contractSignatures.find(
          (signInfo) =>
            signInfo.type === globalContext.type &&
            signInfo.name === globalContext.name
        )

        if (!foundSignature) {
          navigate("/wizard/invalid?type=invalid", { replace: true })
          return
        }

        if (data.cardEmailLink?.expired) {
          navigate("/wizard/invalid?type=invalid", { replace: true })
          return
        }

        if (globalContext.category === "hiring") {
          if (
            !data.cardLimitDate ||
            new Date() > new Date(data.cardLimitDate.date)
          ) {
            navigate(`../wizard/invalid?type=invalid`, { replace: true })
            return
          }
        }

        if (data.contractStatus === SignStatus.signed) {
          navigate("/wizard/signing/signed")
          return
        }

        if (foundSignature?.status === SignatureSignStatus.signed) {
          navigate("/wizard/signing/pending")
          return
        }
      },
      onError: (err) => {
        dispatchToast({
          type: "error",
          content:
            err.data?.userFriendlyError.message || i18n.t("error.defaultError"),
        })
        navigate("/wizard/invalid?type=invalid", { replace: true })
      },
    }
  )

  React.useEffect(() => {
    if (!route) return

    let pageEnum: PageEnum
    switch (route) {
      case "start":
        pageEnum = PageEnum.start
        break
      case "visualizeContract":
        pageEnum = PageEnum.visualizeContract
        break
      case "confirmData":
        pageEnum = PageEnum.confirmData
        break
      case "signData":
        pageEnum = PageEnum.signData
        break
      case "pending":
      case "signed":
        pageEnum = PageEnum.signed
        break
      case "download":
        pageEnum = PageEnum.download
        break
      default:
        pageEnum = PageEnum.start
        break
    }

    if (pageEnum === page) return
    setPage(pageEnum)
  }, [route])

  const hasHeader = React.useMemo(() => {
    return [
      PageEnum.visualizeContract,
      PageEnum.confirmData,
      PageEnum.signData,
    ].includes(page)
  }, [page])

  const handleSetConfirmationData = ({
    documentNumber,
    email,
    name,
  }: ConfirmationDataType) => {
    setConfirmationData({
      documentNumber,
      email,
      name,
    })
  }

  if (!data) {
    return (
      <Container>
        <LoadingArea>
          <Skeleton width={647} height={500} variant="rectangular" />
        </LoadingArea>
      </Container>
    )
  }

  return (
    <signingContext.Provider
      value={{
        signData: data,
        confirmationData,
        handleSetConfirmationData,
      }}
    >
      <Container>
        {data && hasHeader && (
          <Header
            companyName={data.companyName}
            companyLogo={data.companyLogo}
          />
        )}

        <Content>{ContentPage[page]}</Content>
      </Container>
    </signingContext.Provider>
  )
}

export default Signing
